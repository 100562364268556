import React from 'react';
import DistributorAccessComponent from '../components/distributorsAccess/DistributorAccessComponent';

const DistributorAccess = () => {
    return(
        <>
            <DistributorAccessComponent/>
        </>
    );
}

export default DistributorAccess;