import React from 'react';
import AboutContainer from '../components/aboutComponent/AboutComponent';

const About = () =>{
    return(
        <>
            <AboutContainer/>
        </>
    );
}

export default About;