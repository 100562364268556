import React from 'react';
import ContactComponent from '../components/contactForm/ContactComponent';

const Contact = () => {
    return(
        <>
            <ContactComponent/>
        </>
    );
}

export default Contact;