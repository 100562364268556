import React from 'react';
import ServicesInfoComponent from './../components/servicesInfo/ServicesInfoComponent'

const Services = () =>{
    return(
        <>
            <ServicesInfoComponent/>
        </>
    );
}

export default Services;