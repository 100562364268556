import React from 'react';
import { Body, Container, Title, Span } from '../header/Header';

export default function TutorialsComponent(){
    return(
        <Body>
            <Container>
                <Title><Span>M</Span>anuales y Vídeos Tutoriales</Title>
            </Container>
        </Body>
    );
}